import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const HeaderContainer = styled.header`
  background-color: #1a1a1a;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const logoHover = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
`;

const Logo = styled.img`
  height: 40px;
  transition: transform 0.3s ease;

  &:hover {
    animation: ${logoHover} 0.5s ease;
  }
`;

const Links = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const linkHover = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-3px); }
  100% { transform: translateY(0); }
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #5128f5;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover {
    color: #5128f5;
    animation: ${linkHover} 0.3s ease;

    &::after {
      transform: scaleX(1);
    }
  }
`;

const ServerAddressButton = styled.button`
  background-color: #5128f5;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #3e1cc4;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const CopiedMessage = styled.span`
  position: absolute;
  background-color: #4CAF50;
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.3s ease;
  pointer-events: none;
`;

const Header = () => {
  const [showCopied, setShowCopied] = useState(false);

  const handleCopyIP = () => {
    navigator.clipboard.writeText('play.pelicraft.net');
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 2000);
  };

  return (
    <HeaderContainer>
      <Nav>
        <Logo src="/logotrans.png" alt="Pelicraft Logo" />
        <Links>
          <Link href="/">Home</Link>
          <Link href="/map">Map</Link>
          <Link href="/rules">Rules</Link>
          <Link href="https://discord.gg/8bsRvqczuU">Discord</Link>
        </Links>
        <div style={{ position: 'relative' }}>
          <ServerAddressButton onClick={handleCopyIP}>
            play.pelicraft.net
          </ServerAddressButton>
          <CopiedMessage show={showCopied}>Copied!</CopiedMessage>
        </div>
      </Nav>
    </HeaderContainer>
  );
};


export default Header;
