// not sure why i made this

import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { createGlobalStyle, css, keyframes } from 'styled-components';

const GRID_SIZE = 20;
const CELL_SIZE = 25;
const INITIAL_SNAKE = [{ x: 10, y: 10 }];
const INITIAL_DIRECTION = { x: 1, y: 0 };
const INITIAL_FOOD = { x: 15, y: 15 };

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f0f0f0;
    overflow: hidden;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-out;
  outline: none;
`;

const GameBoard = styled.div`
  display: grid;
  grid-template-columns: repeat(${GRID_SIZE}, ${CELL_SIZE}px);
  grid-template-rows: repeat(${GRID_SIZE}, ${CELL_SIZE}px);
  border: 2px solid #333;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
`;

const Cell = styled.div`
  width: ${CELL_SIZE}px;
  height: ${CELL_SIZE}px;
  background-color: ${props => props.type === 'food' ? '#FF5722' : 'white'};
  ${props => props.type === 'food' && css`
    animation: ${pulse} 1s infinite;
  `}
`;

const SnakeSegment = styled.div`
  position: absolute;
  width: ${CELL_SIZE}px;
  height: ${CELL_SIZE}px;
  background-color: #4CAF50;
  border-radius: 50%;
  transition: all 0.05s linear; 
`;

const ScoreBoard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: ${GRID_SIZE * CELL_SIZE}px;
  margin-top: 20px;
`;

const ScoreDisplay = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

const Score = styled.div`
  font-size: 24px;
  margin-top: 20px;
  font-weight: bold;
  color: #333;
`;

const GameTitle = styled.h1`
  color: #4CAF50;
  font-size: 36px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
`;

const Instructions = styled.div`
  margin-top: 20px;
  padding: 15px;
  background-color: #e0e0e0;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;

  &:hover {
    background-color: #45a049;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const GameOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: 24px;
  animation: ${fadeIn} 0.3s ease-out;
`;

const Snake = () => {
  const [snake, setSnake] = useState(INITIAL_SNAKE);
  const [direction, setDirection] = useState(INITIAL_DIRECTION);
  const [food, setFood] = useState(INITIAL_FOOD);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const gameContainerRef = useRef(null);

  useEffect(() => {
    const storedHighScore = localStorage.getItem('snakeHighScore');
    if (storedHighScore) {
      setHighScore(parseInt(storedHighScore, 10));
    }
  }, []);

  const moveSnake = useCallback(() => {
    if (gameOver) return;

    setSnake(prevSnake => {
      const newSnake = [...prevSnake];
      const head = { ...newSnake[0] };
      head.x += direction.x;
      head.y += direction.y;

      if (head.x < 0 || head.x >= GRID_SIZE || head.y < 0 || head.y >= GRID_SIZE) {
        setGameOver(true);
        return prevSnake;
      }

      if (newSnake.some(segment => segment.x === head.x && segment.y === head.y)) {
        setGameOver(true);
        return prevSnake;
      }

      newSnake.unshift(head);

      if (head.x === food.x && head.y === food.y) {
        setScore(prevScore => {
          const newScore = prevScore + 1;
          if (newScore > highScore) {
            setHighScore(newScore);
            localStorage.setItem('snakeHighScore', newScore.toString());
          }
          return newScore;
        });
        setFood({
          x: Math.floor(Math.random() * GRID_SIZE),
          y: Math.floor(Math.random() * GRID_SIZE),
        });
      } else {
        newSnake.pop();
      }

      return newSnake;
    });
  }, [direction, food, gameOver, highScore]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        e.preventDefault();
      }

      switch (e.key) {
        case 'ArrowUp':
          if (direction.y === 0) setDirection({ x: 0, y: -1 });
          break;
        case 'ArrowDown':
          if (direction.y === 0) setDirection({ x: 0, y: 1 });
          break;
        case 'ArrowLeft':
          if (direction.x === 0) setDirection({ x: -1, y: 0 });
          break;
        case 'ArrowRight':
          if (direction.x === 0) setDirection({ x: 1, y: 0 });
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    const gameLoop = setInterval(moveSnake, 100); 

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      clearInterval(gameLoop);
    };
  }, [moveSnake, direction]);

  useEffect(() => {
    if (gameContainerRef.current) {
      gameContainerRef.current.focus();
    }
  }, []);

  const resetGame = () => {
    setSnake(INITIAL_SNAKE);
    setDirection(INITIAL_DIRECTION);
    setFood(INITIAL_FOOD);
    setScore(0);
    setGameOver(false);
    if (gameContainerRef.current) {
      gameContainerRef.current.focus();
    }
  };

  return (
    <>
      <GlobalStyle />
      <GameContainer ref={gameContainerRef} tabIndex="0">
        <GameTitle>Snake</GameTitle>
        <GameBoard>
          {Array.from({ length: GRID_SIZE * GRID_SIZE }).map((_, index) => {
            const x = index % GRID_SIZE;
            const y = Math.floor(index / GRID_SIZE);
            const isFood = food.x === x && food.y === y;
            return <Cell key={index} type={isFood ? 'food' : 'empty'} />;
          })}
          {snake.map((segment, index) => (
            <SnakeSegment
              key={index}
              style={{
                left: `${segment.x * CELL_SIZE}px`,
                top: `${segment.y * CELL_SIZE}px`,
              }}
            />
          ))}
          {gameOver && (
            <GameOverlay>
              <h2>Game Over!</h2>
              <p>Your score: {score}</p>
              <p>High score: {highScore}</p>
              <Button onClick={resetGame}>Play Again</Button>
            </GameOverlay>
          )}
        </GameBoard>
        <ScoreBoard>
          <ScoreDisplay>Score: {score}</ScoreDisplay>
          <ScoreDisplay>High Score: {highScore}</ScoreDisplay>
        </ScoreBoard>
        <Instructions>
          <h3>How to Play:</h3>
          <p>Use the arrow keys to control the snake</p>
          <p>Eat the things to grow</p>
          <p>dont die</p>
        </Instructions>
      </GameContainer>
    </>
  );
};

export default Snake;