import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Gallery from './components/Gallery';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Snake from './components/Snake';
import Rules from './components/Rules'; 
import WorldDownload from './components/WorldDownload';
import Map from './components/Map'; 

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f5f5f5;
    overflow-x: hidden;
  }

  html {
    scroll-behavior: smooth;
  }
`;

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <Hero />
            <Features />
            <Gallery />
            <FAQ />
          </>
        } />
        <Route path="/snake" element={<Snake />} />
        <Route path="/rules" element={<Rules />} /> 
        <Route path="/world-downloads" element={<WorldDownload />} />
        <Route path="/map" element={<Map />} /> 
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
