import React from 'react';
import styled from 'styled-components';

const HeroSection = styled.section`
  background-image: url('/download-1500w.png');
  background-size: cover;
  background-position: center;
  height: 543px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
`;

const Title = styled.h1`
  font-size: 4rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const CTAButton = styled.a`
  background-color: #5128f5;
  color: white;
  padding: 1rem 2rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #3e1cc4;
  }
`;

const Hero = () => {
  return (
    <HeroSection>
      <Title>Pelicraft SMP</Title>
      <Subtitle>Season 8 coming soon.</Subtitle>
      <CTAButton href="https://discord.gg/8bsRvqczuU">Join Us!</CTAButton>
    </HeroSection>
  );
};

export default Hero;
