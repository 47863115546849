import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaExternalLinkAlt } from 'react-icons/fa';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;


const shimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const MapContainer = styled.div`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
    margin: 2rem 1rem;
  }
`;

const Title = styled.h1`
  color: #2c3e50;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 3.5rem;
  font-weight: 800;
  letter-spacing: -1px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  animation: ${fadeIn} 1s ease-out;
  background: linear-gradient(90deg, #2c3e50, #4ca1af, #2c3e50);
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: ${shimmer} 3s linear infinite;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const MapFrame = styled.iframe`
  width: 100%;
  height: 600px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

const Button = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  background: linear-gradient(135deg, #3498db 0%, #2980b9 100%);
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(52, 152, 219, 0.4);
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(52, 152, 219, 0.6);
  }

  svg {
    margin-left: 10px;
  }
`;


const GradientBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(
    135deg,
    hsl(${props => props.hue}, 50%, 80%) 0%,
    hsl(${props => props.hue + 60}, 50%, 80%) 100%
  );
  transition: all 2s ease;
`;


const Map = () => {
  const [hue, setHue] = useState(180);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
      setHue(180 + scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <GradientBackground hue={hue} />
      <MapContainer>
        <Title>Pelicraft Server Map</Title>
        
        <MapFrame src="https://map.pelicraft.net" title="Pelicraft Server Map" />
        
        <Button href="https://map.pelicraft.net" target="_blank" rel="noopener noreferrer">
          Open Full Map <FaExternalLinkAlt />
        </Button>

      </MapContainer>
    </>
  );
};

export default Map;
