import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaDownload, FaLock, FaCube, FaFileArchive } from 'react-icons/fa';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-10px) rotate(5deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const shimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const WorldContainer = styled.div`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
    margin: 2rem 1rem;
  }
`;

const Title = styled.h1`
  color: #2c3e50;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 3.5rem;
  font-weight: 800;
  letter-spacing: -1px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  animation: ${fadeIn} 1s ease-out;
  background: linear-gradient(90deg, #2c3e50, #4ca1af, #2c3e50);
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: ${shimmer} 3s linear infinite;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const WorldGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const WorldCard = styled.div`
  background: ${props => props.available ? 'linear-gradient(135deg, #f6d365 0%, #fda085 100%)' : 'linear-gradient(135deg, #bdc3c7 0%, #2c3e50 100%)'};
  border-radius: 15px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${props => props.delay}s;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: ${props => props.available ? 'translateY(-10px)' : 'none'};
    box-shadow: ${props => props.available ? '0 15px 30px rgba(0,0,0,0.2)' : 'none'};
  }

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 80%);
    transform: rotate(30deg);
  }
`;

const WorldTitle = styled.h2`
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
`;

const WorldDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1.5rem;
  flex-grow: 1;
`;

const WorldInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1.5rem;
`;

const InfoItem = styled.span`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;

const DownloadButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: ${props => props.available ? '#2ecc71' : '#95a5a6'};
  color: white;
  text-decoration: none;
  border-radius: 25px;
  font-weight: bold;
  transition: all 0.3s ease;
  cursor: ${props => props.available ? 'pointer' : 'not-allowed'};
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: ${props => props.available ? '#27ae60' : '#95a5a6'};
    transform: ${props => props.available ? 'translateY(-2px)' : 'none'};
    box-shadow: ${props => props.available ? '0 5px 15px rgba(46, 204, 113, 0.4)' : 'none'};
  }

  &::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 80%);
    transform: rotate(30deg);
    transition: all 0.3s ease;
    opacity: 0;
  }

  &:hover::after {
    opacity: 1;
  }

  svg {
    margin-right: 10px;
  }
`;

const Footer = styled.p`
  font-style: italic;
  text-align: center;
  margin-top: 3rem;
  font-size: 1rem;
  color: #7f8c8d;
  animation: ${fadeIn} 1s ease-out;
  animation-delay: 2s;
  line-height: 1.6;
`;

const GradientBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(
    135deg,
    hsl(${props => props.hue}, 50%, 80%) 0%,
    hsl(${props => props.hue + 60}, 50%, 80%) 100%
  );
  transition: all 2s ease;
`;

const FloatingCube = styled(FaCube)`
  position: absolute;
  color: rgba(255, 255, 255, 0.3);
  animation: ${float} 6s infinite ease-in-out;
  font-size: ${props => props.size}px;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
`;

// World data
const worldData = [
  {
    id: 1,
    name: "Season 1",
    description: "The very first Pelicraft Season. Explore the rampant admin abuse and hidious builds.",
    mcVersion: "1.16.4",
    size: "212 MB",
    fileName: "season1.zip",
    available: true,
  },
  {
    id: 2,
    name: "Season 2",
    description: "Our second season, Lots of wooden roads.",
    mcVersion: "1.16.4",
    size: "303 MB",
    fileName: "season2.zip",
    available: true,
  },
  {
    id: 3,
    name: "Season 3",
    description: "The starting point for many, Season three was one of our most popular.",
    mcVersion: "1.17",
    size: "215 MB",
    fileName: "season3.zip",
    available: true,
  },
  {
    id: 3,
    name: "Season 4",
    description: "Very spread out, you might have trouble finding peoples bases.",
    mcVersion: "1.18.2",
    size: "453 MB",
    fileName: "season4.zip",
    available: true,
  },
  {
    id: 3,
    name: "Season 5",
    description: "Big ol castle. I think we had a texture pack for this one, but if we did it has since been lost to time.",
    mcVersion: "1.19",
    size: "206 MB",
    fileName: "season5.zip",
    available: true,
  },
  {
    id: 3,
    name: "Season 6",
    description: "The world files for season 6 were unfortunately lost due to an issue with our backup system. We apologize for the inconvenience.",
    mcVersion: "1.19.2",
    size: "N/A",
    fileName: "season6.zip",
    available: false,
  },
  {
    id: 3,
    name: "Season 7",
    description: "Our most recent season, Hateno Village remains unfinished 😔",
    mcVersion: "1.20",
    size: "852 MB",
    fileName: "season7.zip",
    available: true,
  },
];

const WorldDownload = () => {
  const [hue, setHue] = useState(180);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
      setHue(180 + scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <GradientBackground hue={hue} />
      <WorldContainer>
        <FloatingCube size={50} top={10} left={5} />
        <FloatingCube size={30} top={50} left={90} />
        <FloatingCube size={40} top={80} left={20} />
        <Title>World Downloads</Title>
        
        <WorldGrid>
          {worldData.map((world, index) => (
            <WorldCard key={world.id} delay={0.2 * (index + 1)} available={world.available}>
              <WorldTitle>{world.name}</WorldTitle>
              <WorldDescription>{world.description}</WorldDescription>
              <WorldInfo>
                <InfoItem><FaCube /> {world.mcVersion}</InfoItem>
                <InfoItem><FaFileArchive /> {world.size}</InfoItem>
              </WorldInfo>
              <DownloadButton 
                href={world.available ? `/worlds/${world.fileName}` : undefined} 
                available={world.available}
                onClick={(e) => !world.available && e.preventDefault()}
                download={world.available ? world.fileName : undefined}
              >
                {world.available ? (
                  <>
                    <FaDownload /> Download
                  </>
                ) : (
                  <>
                    <FaLock /> Not Available
                  </>
                )}
              </DownloadButton>
            </WorldCard>
          ))}
        </WorldGrid>

        <Footer>
          All world files are compressed for an easier download. Please contact Peli if parts of a world are missing.
        </Footer>
      </WorldContainer>
    </>
  );
};

export default WorldDownload;