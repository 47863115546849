import React from 'react';
import styled from 'styled-components';

const images = [
  'smp9.png', 'smp15.png', 'smp17.png', 'smp2.png',
  'smp22.png', 'smp16.png', 'smp5.png', 'smp20.png',
  'smp12.png', 'smp14.png'
];

const GallerySection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
`;

const GalleryItem = styled.div`
  width: calc(25% - 1rem);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 1024px) {
    width: calc(33.33% - 1rem);
  }

  @media (max-width: 768px) {
    width: calc(50% - 1rem);
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const Gallery = () => {
  return (
    <GallerySection>
      {images.map((img, index) => (
        <GalleryItem key={index}>
          <GalleryImage src={`/photos/${img}`} alt={`Minecraft screenshot ${index + 1}`} />
        </GalleryItem>
      ))}
    </GallerySection>
  );
};

export default Gallery;
