import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const faqData = [
  {
    question: "How do I join the Pelicraft SMP?",
    answer: "To join the SMP, simply add the server to your multiplayer list with the IP play.pelicraft.net. If you're having issues connecting or need help, feel free to reach out to us on our Discord server."
  },
  {
    question: "What version of Minecraft does the server run?",
    answer: "Our server currently supports all recent versions of Minecraft Java Edition. We try to update promptly when new versions are released to give players access to new features."
  },
  {
    question: "Can I join on Bedrock or Pocket Edition?",
    answer: "At this point in time the Pelicraft SMP is Java only. However, we may consider crossplay in the future if we see enough demand."
  },
  {
    question: "Are there any rules on the server?",
    answer: (
      <>
        Yes, we have a set of rules to ensure a fair and enjoyable experience for all players. These include no griefing, no cheating or using unfair mods, and being respectful to other players. You can find the full list of rules on our <Link to="/rules" style={{color: '#5128f5', textDecoration: 'underline'}}>Rules page</Link> or on our Discord server.
      </>
    )
  },
  {
    question: "How often does the server reset?",
    answer: "We typically run seasons that last around 6 months to a year. At the end of each season, we reset the world to provide a fresh start for all players. We always announce upcoming resets well in advance on our Discord server."
  },
];

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const FAQSection = styled.section`
  max-width: 900px;
  margin: 4rem auto;
  padding: 3rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  animation: ${fadeIn} 1s ease-out;
`;

const FAQTitle = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
  color: #2c3e50;
  font-size: 2.5rem;
  font-weight: 700;
`;

const FAQDescription = styled.p`
  text-align: center;
  margin-bottom: 3rem;
  color: #34495e;
  font-size: 1.1rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const FAQItem = styled.div`
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.5s ease;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

const FAQQuestion = styled.div`
  padding: 1.25rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  color: #2c3e50;
  background-color: #ffffff;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: #f1f3f5;
  }
`;

const FAQAnswer = styled.div`
  padding: 0 1.25rem;
  max-height: ${props => props.isOpen ? '1000px' : '0'};
  opacity: ${props => props.isOpen ? '1' : '0'};
  overflow: hidden;
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;

const AnswerContent = styled.div`
  padding: 1.25rem 0;
  color: #34495e;
  line-height: 1.6;
`;


const ChevronIcon = styled(FaChevronDown)`
  transition: transform 0.5s ease;
  transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0)'};
  color: #5128f5;
`;

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <FAQSection>
      <FAQTitle>Frequently Asked Questions</FAQTitle>
      <FAQDescription>
        Can't find your answer? Feel free to harass us on our discord server!
      </FAQDescription>
      {faqData.map((item, index) => (
        <FAQItem key={index}>
          <FAQQuestion onClick={() => toggleAccordion(index)}>
            {item.question}
            <ChevronIcon isOpen={openIndex === index} />
          </FAQQuestion>
          <FAQAnswer isOpen={openIndex === index}>
          <AnswerContent>{typeof item.answer === 'string' ? item.answer : item.answer}</AnswerContent>
          </FAQAnswer>
        </FAQItem>
      ))}
    </FAQSection>
  );
};

export default FAQ;
