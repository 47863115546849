import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaDiscord, FaUserFriends, FaBook, FaDownload, FaMap } from 'react-icons/fa';
import { GiSwordsPower } from 'react-icons/gi';
import { Link } from 'react-router-dom';

const FeaturesSection = styled.section`
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const TopRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const BottomRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
`;

const CardTitle = styled.h3`
  margin: 1rem 0;
  color: #333;
  font-size: 1.6rem;
  font-weight: 600;
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  color: #5128f5;
`;

const ServerStatus = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${props => props.online ? '#4CAF50' : '#F44336'};
  margin: 1rem 0;
`;

const PlayerCount = styled.p`
  font-size: 1.2rem;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #5128f5;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  margin-top: auto;

  &:hover {
    background-color: #3e1cc5;
  }
`;

const CardDescription = styled.p`
  color: #666;
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
`;

const Features = () => {
  const [serverStatus, setServerStatus] = useState(null);

  useEffect(() => {
    const fetchServerStatus = async () => {
      try {
        const response = await fetch('https://api.mcsrvstat.us/2/play.pelicraft.net');
        const data = await response.json();
        setServerStatus(data);
      } catch (error) {
        console.error('Error fetching server status:', error);
      }
    };

    fetchServerStatus();
    const interval = setInterval(fetchServerStatus, 60000); 

    return () => clearInterval(interval);
  }, []);

  const topCards = [
    {
      icon: <GiSwordsPower />,
      title: "Server Status",
      content: serverStatus ? (
        <>
          <ServerStatus online={serverStatus.online}>
            {serverStatus.online ? 'Online' : 'Offline'}
          </ServerStatus>
          {serverStatus.online && (
            <PlayerCount>
              <FaUserFriends /> {serverStatus.players.online} / {serverStatus.players.max} Players Online
            </PlayerCount>
          )}
        </>
      ) : (
        <ServerStatus>Loading...</ServerStatus>
      )
    },
    {
      icon: <FaDiscord />,
      title: "Join Our Discord",
      description: "Connect with other players and stay updated",
      button: { text: "Join Discord", link: "https://discord.gg/8bsRvqczuU", external: true }
    }
  ];

  const bottomCards = [
    {
      icon: <FaMap />,
      title: "Server Map",
      description: "Explore our server's live map",
      button: { text: "View Map", link: "/map" }
    },
    {
      icon: <FaBook />,
      title: "Server Rules",
      description: "Apparently we need these",
      button: { text: "View Rules", link: "/rules" }
    },
    {
      icon: <FaDownload />,
      title: "World Downloads",
      description: "Download previous world saves",
      button: { text: "Get Downloads", link: "/world-downloads" }
    }
  ];

  return (
    <FeaturesSection>
      <FeaturesGrid>
        <TopRow>
          {topCards.map((card, index) => (
            <Card key={index}>
              <IconWrapper>{card.icon}</IconWrapper>
              <CardTitle>{card.title}</CardTitle>
              {card.description && <CardDescription>{card.description}</CardDescription>}
              {card.content}
              {card.button && (
                <Button 
                  as={card.button.external ? "a" : Link} 
                  to={!card.button.external ? card.button.link : undefined}
                  href={card.button.external ? card.button.link : undefined}
                  target={card.button.external ? "_blank" : undefined}
                  rel={card.button.external ? "noopener noreferrer" : undefined}
                >
                  {card.button.text}
                </Button>
              )}
            </Card>
          ))}
        </TopRow>
        <BottomRow>
          {bottomCards.map((card, index) => (
            <Card key={index}>
              <IconWrapper>{card.icon}</IconWrapper>
              <CardTitle>{card.title}</CardTitle>
              <CardDescription>{card.description}</CardDescription>
              <Button 
                to={card.button.link}
              >
                {card.button.text}
              </Button>
            </Card>
          ))}
        </BottomRow>
      </FeaturesGrid>
    </FeaturesSection>
  );
};

export default Features;
