import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaBook, FaGamepad } from 'react-icons/fa';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-10px) rotate(5deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const shimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const RulesContainer = styled.div`
  max-width: 1000px;
  margin: 4rem auto;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
    margin: 2rem 1rem;
  }
`;

const Title = styled.h1`
  color: #2c3e50;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 3.5rem;
  font-weight: 800;
  letter-spacing: -1px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  animation: ${fadeIn} 1s ease-out;
  background: linear-gradient(90deg, #2c3e50, #4ca1af, #2c3e50);
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: ${shimmer} 3s linear infinite;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Section = styled.div`
  margin-bottom: 3rem;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${props => props.delay}s;
`;

const SectionTitle = styled.h2`
  color: #3498db;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const RulesList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const RuleItem = styled.li`
  margin-bottom: 1.5rem;
  padding-left: 2.5rem;
  position: relative;
  font-size: 1.1rem;
  line-height: 1.6;
  transition: all 0.3s ease;

  &:before {
    content: "✦";
    color: #2ecc71;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.5rem;
    transition: all 0.3s ease;
  }

  &:hover {
    transform: translateX(5px);
    &:before {
      color: #3498db;
      transform: rotate(180deg);
    }
  }
`;

const Footer = styled.p`
  font-style: italic;
  text-align: center;
  margin-top: 3rem;
  font-size: 1rem;
  color: #7f8c8d;
  animation: ${fadeIn} 1s ease-out;
  animation-delay: 2s;
  line-height: 1.6;
`;

const GradientBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(
    135deg,
    hsl(${props => props.hue}, 50%, 80%) 0%,
    hsl(${props => props.hue + 60}, 50%, 80%) 100%
  );
  transition: all 2s ease;
`;

const FloatingIcon = styled.div`
  position: absolute;
  color: rgba(255, 255, 255, 0.3);
  animation: ${float} 6s infinite ease-in-out;
  font-size: ${props => props.size}px;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
`;

const Rules = () => {
  const [hue, setHue] = useState(180);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
      setHue(180 + scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <GradientBackground hue={hue} />
      <RulesContainer>
        <FloatingIcon as={FaBook} size={50} top={10} left={5} />
        <FloatingIcon as={FaGamepad} size={30} top={50} left={90} />
        <FloatingIcon as={FaBook} size={40} top={80} left={20} />
        <Title>Server Rules</Title>
        
        <Section delay={0.2}>
          <SectionTitle>General Rules</SectionTitle>
          <RulesList>
            <RuleItem>This is a 13+ server.</RuleItem>
            <RuleItem>We're (mostly) a family-friendly server: We want everyone to feel comfortable and respected so please keep adult subjects to yourself.</RuleItem>
            <RuleItem>Zero tolerance for doxxing: We take privacy seriously, so please don't share personal info about anyone, including yourself.</RuleItem>
            <RuleItem>English only, please: This helps us all communicate smoothly and avoid misunderstandings.</RuleItem>
            <RuleItem>No harassment or bullying: It's not that hard to be kind and respectful to others, so please be nice!</RuleItem>
            <RuleItem>No sexual content: We have minors on the server, so please keep it PG outside of specific channels.</RuleItem>
            <RuleItem>Venting in DMs, please! If you need to talk about something heavy, we would rather you keep it in a private setting.</RuleItem>
            <RuleItem>No spamming messages!: It's just annoying.</RuleItem>
            <RuleItem>Bigotry has no place here: We're a community that values inclusivity and respect for everyone, regardless of their background or identity.</RuleItem>
          </RulesList>
        </Section>

        <Section delay={0.4}>
          <SectionTitle>SMP Rules</SectionTitle>
          <RulesList>
            <RuleItem>All previous rules apply.</RuleItem>
            <RuleItem>No griefing or stealing. We aren't that kind of community, if you want to cause chaos find an anarchy server.</RuleItem>
            <RuleItem>No targeted harassment: Do not partake in bullying or spawn killing.</RuleItem>
            <RuleItem>No cheating, hacking, XRAY texture packs, or mining/path tracing hacks like baritone. We do however allow quality of life client side modifications such as Litematica or Minihud.</RuleItem>
          </RulesList>
        </Section>

        <Footer>
          We aren't overly strict around here so as long as you're respectful and decently well behaved you won't have to worry about the rules.
          If you are unsure whether something is allowed please reach out to a staff member.
        </Footer>
      </RulesContainer>
    </>
  );
};

export default Rules;
