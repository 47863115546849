import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaDiscord, FaYoutube, FaTiktok, FaInstagram, FaGithub } from 'react-icons/fa';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const FooterContainer = styled.footer`
  background-color: #1a1a1a;
  color: white;
  padding: 3rem 2rem;
  animation: ${fadeIn} 0.5s ease-out;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  gap: 2rem;
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
`;

const FooterTitle = styled.h3`
  margin-bottom: 1rem;
  color: #5128f5;
  font-size: 1.2rem;
`;

const FooterLink = styled.a`
  color: #cccccc;
  text-decoration: none;
  margin-bottom: 0.7rem;
  transition: color 0.3s ease, transform 0.3s ease;

  &:hover {
    color: white;
    transform: translateX(5px);
  }
`;

const FooterLogo = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const Copyright = styled.p`
  text-align: center;
  margin-top: 3rem;
  color: #888;
  font-size: 0.9rem;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SocialIcon = styled.a`
  color: #cccccc;
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;

  &:hover {
    color: #5128f5;
    transform: translateY(-3px);
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterTitle>Useful Links</FooterTitle>
          <FooterLink href="/rules">Rules</FooterLink>
          <FooterLink href="/snake">Snake</FooterLink>
          <FooterLink href="/world-downloads">World Downloads</FooterLink>
          <FooterLink href="#">Staff Application (closed)</FooterLink>
          <FooterLink href="https://disboard.org/server/773807986430443532">Disboard</FooterLink>
          <FooterLink href="/map">Map</FooterLink>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Connect With Us</FooterTitle>
          <SocialIcons>
            <SocialIcon href="https://discord.gg/8bsRvqczuU" target="_blank" rel="noopener noreferrer">
              <FaDiscord />
            </SocialIcon>
            <SocialIcon href="https://www.youtube.com/@Pelicraft" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </SocialIcon>
            <SocialIcon href="https://www.tiktok.com/@pelicraftSMP" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </SocialIcon>
            <SocialIcon href="https://www.instagram.com/pelicraft/" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </SocialIcon>
            <SocialIcon href="https://github.com/tallpelican" target="_blank" rel="noopener noreferrer">
              <FaGithub />
            </SocialIcon>
          </SocialIcons>
        </FooterSection>
        <FooterSection>
          <FooterLogo src="/logotrans.png" alt="Pelicraft logo" />
        </FooterSection>
      </FooterContent>
      <Copyright>Copyright © Pelicraft - {new Date().getFullYear()}</Copyright>
    </FooterContainer>
  );
};

export default Footer;
